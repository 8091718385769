
  import { Component } from 'vue-property-decorator'
  import Cell from './cell'
  import { fixPrice } from '@/utils/general'
  import { BasicCellOptions, PriceCellOptions } from '@/components/dataTables/cell/index'
  import AlertCell from '@/components/dataTables/cell/AlertCell.vue'

@Component({
  components: { AlertCell },
})
  export default class PriceCell extends Cell {
  declare options: BasicCellOptions | PriceCellOptions

  get price () {
    const { item } = this
    if (!item) return undefined
    const { value, pie, noFormat = false } = item
    if (!value) return '$0'

    if (pie) {
      return fixPrice(value * pie)
    }
    if (noFormat) {
      return value
    }

    return fixPrice(value) || '$0'
  }

  get title () {
    const { item: { name } } = this
    return name
  }

  get indicator () {
    const { item: { indicator } } = this
    return indicator
  }

  get colTitle () {
    const { options } = this

    if (!options) return 5
    const option = options as PriceCellOptions
    return option?.colTitle
  }

  get colValue () {
    const { options } = this

    if (!options) return 7
    const option = options as PriceCellOptions
    return option?.colValue
  }

  get valueCenter () {
    const { options } = this

    if (!options) return false
    const option = options as PriceCellOptions
    return option?.valueCenter
  }
  }
